<template>
  <ion-toolbar>
    <card-change-company
      :selectedCompany="selectedCompany"
      :isHideSome="!isOnScrollTop"
      @refreshData="$emit('reRenderToolbar')"
    />
    <ion-grid>
      <ion-row class="align-center">
        <ion-col size="9" class="d-flex">
          <ion-label class="fs-4 fw-bold">{{ $t('cart') }}</ion-label>
        </ion-col>
        <ion-col size="3" class="header-right d-flex ion-justify-content-end align-center">
          <notification-badge />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</template>
<script>
import CardChangeCompany from '@/modules/shared/components/CardChangeCompany.vue';
import NotificationBadge from '@/modules/shared/components/notifications/NotificationBadge.vue';
import { notificationsOutline, storefrontOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CardChangeCompany,
    NotificationBadge
  },
  emits: ['reRenderToolbar'],
  props: {
    selectedCompany: {
      type: Object,
      default: () => {}
    },
    isOnScrollTop: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      storefrontOutline,
      notificationsOutline
    };
  }
});
</script>
<style lang="scss" scoped>
ion-toolbar {
  --border-width: 0 !important;
}

.icon-item {
  font-size: 24px;
}

.header-right {
  text-align: end;
}

.header-right * {
  color: var(--ion-color-primary);
}
</style>
